

// Make all the things neccessary to show or hide the navigation:
const toggleNavigation = function(){
  document.querySelector('#burger').classList.toggle('close-button');
  document.querySelector('body').classList.toggle('open');
  document.querySelector('#navi').classList.toggle('active');
}

// Toggle Navigation on Clicking the Burger:
const showHideNav = document.querySelector('#burger');
showHideNav.addEventListener('click', toggleNavigation);